@import "styles/components";

.link_wrapper {
  max-width: 250px;
  margin-bottom: 10px;

  p {
    font-size: 0.8rem;
  }
}

.link_btn {
  display: block;
  margin-bottom: 8px;
}
