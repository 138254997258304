@import "styles/colors";

.review {
  margin-bottom: 20px;
}

.flex {
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;

  & > div {
    width: calc(100% / 6);
    text-align: center;
  }
}

.expert {
  margin-bottom: 20px;
  padding: 6px;
  background: rgba($gray, 0.3);
}

.btn {
  margin-left: auto;
}
