@import "./colors";

@mixin input {
  display: block;
  width: 100%;
  max-width: 400px;
  height: 36px;
  padding: 4px 8px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  outline: none;
  background: $white;
  font-size: 1rem;

  &:not(select):focus {
    border-color: $input-border-focus;
  }
}

@mixin input-wrapper {
  font-size: 0.85rem;
  padding: 6px 0;
}

@mixin label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.75rem;
}
