@import "styles/colors";

.header {
  padding-bottom: 20px;
}

.card_status {
  margin: 0 50px;

  &_value {
    border: 1px solid $gray;
    padding: 4px;
    margin-top: 4px;
  }
}
