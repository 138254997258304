@import "styles/components";
@import "styles/colors";

.select_wrapper {
  @include input-wrapper;
}

.select {
  @include input;
  border: none;
  padding: 0;

  & > div[class*="control"] {
    &:hover {
      border-color: $input-border-focus;
    }
    cursor: pointer;
    box-shadow: none;
  }
}

.label {
  @include label;
}
