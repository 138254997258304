@import "styles/colors";

.tabs {
  display: flex;
}

.tab_item {
  padding: 10px 20px;
  background: $gray;
  border: 1px solid $black;
  color: $white;
  cursor: pointer;

  &__active {
    background: $white;
    color: $black;
  }
}
