@import "styles/colors";
@import "styles/components";

.wrapper {
  position: relative;
  padding: 10px;
  border: 2px dashed $draft;

  textarea {
    max-width: 100%;
  }
}

.input {
  @include input;
  min-width: 250px;
}

.flex {
  display: flex;
}

.delete_text {
  margin: auto;
  font-size: 1.2rem;
}

.btn_wrapper {
  position: absolute;
  right: 10px;
  top: 0;
  transform: translateY(-50%);
}
