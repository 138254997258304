@import "styles/colors";

.base {
  max-width: 160px;
  padding: 12px;
}

.decline {
  --btn-bg-color: #{transparentize(red, 0.7)};
}

.approve {
  --btn-bg-color: #{transparentize(lime, 0.7)};
}

.confirm_action {
  font-style: italic;
  background-color: transparentize(lightgrey, 0.5);
  padding: 12px;
  border-radius: 8px;
}
