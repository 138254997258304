@import "styles/colors";
@import "styles/components";

.main {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: $black;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.main_block {
  display: grid;
  grid-template-rows: 1fr 1fr 3fr;
  align-items: center;
}
