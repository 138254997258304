.flex {
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;

  & > div {
    width: calc(100% / 6);
    text-align: center;
  }
}

.title {
  margin-top: 40px;
  font-weight: bold;
  text-align: center;
}
