.go_back {
  margin-right: 15px;
  min-width: unset;
  width: 50px;
  overflow: hidden;
  border: 2px solid;
  border-radius: 50%;

  &_content {
    font-size: 2rem; // chosen empirically to more or less match to wireframes
  }
}
