.login {
  margin: auto;
  padding: 30px 40px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 gray;
  text-align: center;
  min-width: 350px;
}

.header {
  margin-bottom: 30px;
  font-size: 1.4rem;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 80px;
  max-width: 350px;
  font-size: 0.8rem;
  line-height: 2rem;

  strong {
    font-weight: bold;
  }
}

.support {
  width: 100%;
}
