@import "styles/colors";
@import "../FieldSet/styles.module";

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  border: 2px dashed $gray;

  &_removed {
    border-color: $draft;
  }
}

.controls {
  position: absolute;
  top: 0;
  right: $header-h-pad;
  transform: translateY(-50%);
}
