@import "styles/colors";

.expertise {
  margin-bottom: 30px;
  max-width: 1200px;

  & > div:first-child {
    background: rgba($gray, 0.2);
    padding-top: 6px;
  }
}

.header {
  margin-top: 10px;
  font-size: 1.2rem;
}
