.loader {
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgb(0 0 0 / 10%);
}

.svg {
  width: 120px;
  height: 120px;
  margin: auto;
  box-shadow: 0 0 12px 1px #444444;
  border-radius: 50%;
}
