.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 20px;
  text-align: right;
  background: white;
  box-shadow: 0 -1px 5px 0px gray;

  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.error {
  font-size: 1.4rem;

  & > a {
    padding-left: 6px;
  }
}
