@import "styles/colors";
@import "styles/components";

.header {
  display: flex;
  padding: 6px;
  padding-right: 1%;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1600px;
  background: rgba($gray, 0.15);

  &_controls {
    margin-left: auto;
    display: flex;
    gap: 80px;
  }
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.score {
  text-align: center;
  font-weight: 500;

  &_value {
    margin-top: 8px;
    text-align: center;
    background: #ffeca9;
    padding: 6px;
    font-size: 1.2rem;
  }
}
