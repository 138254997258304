.header {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #21272e;
  color: #7f8b98;
  gap: 16px;
}

.nav {
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  align-items: center;

  padding: 0;
  list-style: none;

  .nav_link {
    color: #bbbbbb;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }
  }

  .active {
    color: #dddddd;
    border-bottom: 1px solid;
  }
}

.tagline {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
}

.auth_menu {
  margin-left: auto;
}

.username {
  font-weight: bolder;
  text-decoration: underline;
}

.btn_logout {
  cursor: pointer;
  border: 2px solid white;
  padding: 6px;
}
