.table {
  border-collapse: collapse;

  th {
    font-weight: bold;
  }
}

.cell {
  border: 1px solid black;
  padding: 4px;
  vertical-align: middle;
}

.cell_content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; // for word-wrapped text
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-top: none;
  padding: 8px;
  font-style: italic;
}

.container {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: max-content;
  gap: 8px;
  overflow: auto;
  padding: 8px 0;
}

.placeholder_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1600px;
}
