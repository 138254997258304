@import "styles/colors";
@import "styles/components";

$input-gap: 10px;

.container {
  display: block;

  @include input-wrapper;

  .input,
  select,
  textarea {
    @include input;
  }

  select {
    cursor: pointer;
  }

  input:disabled {
    background-color: #dddddd;
    color: #000000;
  }

  textarea {
    height: 150px;
    resize: none;
    width: 100%;
  }
}

.input_block {
  width: 100%;
}

.label {
  @include label;
}

.switch_block {
  display: flex;
  align-items: center;

  & > .input {
    width: auto;
    margin-right: $input-gap;
    cursor: pointer;
    height: auto;
  }

  & > .label {
    order: 2;
    margin: 0;
    font-size: 0.85rem;
  }
}
