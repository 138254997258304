.flex_container {
  & > label,
  & > div {
    min-width: 250px;
  }
}

textarea.textarea {
  max-width: 100%;
}

.btn_wrapper {
  text-align: right;

  button:first-child {
    margin-right: 10px;
  }
}
