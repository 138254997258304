@import "styles/colors";

.root {
  color: $error;
  font-size: 0.7rem;

  &:not(:empty) {
    margin-top: 4px;
  }
}

.bordered {
  border: 2px solid $error;
  padding: 8px;
  background-color: transparentize($error, 0.9);
}
