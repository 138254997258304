.score {
  &_none {
    background-color: grey;
  }

  &_low {
    background-color: #ff8989;
  }

  &_mid {
    background-color: #ffff7d;
  }

  &_high {
    background-color: #1afb1a;
  }
}

.row {
  &_even {
    background-color: lightgrey;
  }

  &_odd {
    background-color: white;
  }
}

.table {
  th,
  td {
    max-width: 300px;
    padding: 8px;
    box-sizing: content-box;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: lightgrey;
      }
    }
  }
}

.cell {
  &_score {
    min-width: 32px;
    white-space: nowrap;
  }

  &_abbrev {
    width: 44px;
  }
}

.user_name {
  width: 100%;
  text-align: left;
}
