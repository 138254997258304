@import "styles/colors";

$header-h-pad: 20px;

.fieldset {
  border-bottom: $black;
  min-width: 400px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 4px $header-h-pad;
  background: $gray;
  color: $white;
  border-radius: 4px;
  min-height: 35px;
}

.title {
  flex-grow: 1;
}

.controls {
  display: flex;
  align-items: center;
  gap: 12px;
  color: $black;
}

.dialog_reject_draft {
  width: 100%;
  text-align: left;

  textarea {
    max-width: 100%;
  }
}

.draft {
  .header {
    border: 1px solid $draft;
  }

  .fields {
    input,
    textarea,
    div[class*="control"] {
      border: 1px solid $draft;
      pointer-events: none;
    }
  }
}
