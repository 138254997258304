@import "styles/colors";

$z-index-overlay: 999;

.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5);
  z-index: $z-index-overlay;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  min-width: 200px;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 8px;
  background: $white;
  text-align: center;
  z-index: $z-index-overlay + 1;
}

.title {
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 20px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.confirm_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.confirm_footer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn_confirm {
  --btn-bg-color: #{transparentize(skyblue, 0.7)};
}

.btn_cancel {
  --btn-bg-color: #{transparentize(red, 0.9)};
}
