@import "styles/colors";
@import "styles/components";

.file_wrapper {
  @include input-wrapper;

  & > .input {
    @include input;
    cursor: pointer;
  }
}

.file_block {
  display: flex;
  align-items: center;

  & > input[type="file"] {
    display: none;
  }

  button,
  a.input {
    width: auto;
    min-width: auto;
    height: 30px;
    margin-left: 10px;
    border: 1px solid #ced4da;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      background: $gray;
      color: $white;
    }
  }

  .delete_btn {
    border: 1px solid $error;
  }
}
