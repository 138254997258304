@import "styles/colors";

.contacts {
  flex-grow: 1;
}

.header {
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-weight: 500;
}

.contact {
  display: flex;
  justify-content: space-between;
  padding: 6px;

  &:nth-child(even) {
    background: rgba($black, 0.1);
  }
}

.link {
  padding-left: 10px;
}
