@import "styles/colors";
@import "styles/components";

.main {
  max-width: 1024px;
  margin: auto;
  padding: 5% 20px;
}

.header {
  font-size: 1.8rem;
  margin-bottom: 40px;
  line-height: 2rem;
}

.title {
  font-size: 1.2rem;
  text-decoration: underline;
}

.doc_list {
  list-style: auto;
  margin: 15px;
  margin-bottom: 30px;

  & > li {
    margin-left: 20px;
    line-height: 1.2rem;
  }
}

.rules {
  margin-top: 40px;

  strong {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 8px;
  }
}

.link_wrapper {
  text-align: right;
  margin-right: 60px;
  margin-top: 100px;
}
