.filter_field {
  display: flex;
  gap: 8px;
  align-items: center;
}

.label {
  max-width: 190px;
  display: block;
}
