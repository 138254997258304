@import "styles/components";
@import "styles/colors";

.main {
  padding: 0 20px;
  margin: auto;
}

.header {
  font-size: 1.4rem;
  margin-bottom: 40px;
}

.main .form {
  width: 100%;
  max-width: 600px;

  input:not(input[type="radio"]) {
    width: 100%;
  }
}

.button_wrapper {
  margin: 20px 0;
  text-align: right;
}
