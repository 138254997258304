$site-width: none;
$content-width: unset;
$header-height: 60px;
$footer-height: 60px;

.main_layout {
  height: 100vh;

  margin: auto;
  max-width: $site-width;

  display: grid;
  grid-template-rows: $header-height auto $footer-height;
}

.scroller {
  padding: 24px;
  overflow-y: auto;
  display: flex;
}

.content {
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: $content-width;
}
