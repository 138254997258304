@import "styles/colors";

@mixin button($pad: 4px 8px, $text-transform: uppercase) {
  display: inline-block;
  min-width: 100px;

  padding: $pad;

  text-align: center;
  text-decoration: none;

  border-style: solid;
  border-radius: 3px;
  border-width: 1px;

  text-transform: $text-transform;
  font-size: 0.8rem;
  transition: 0.3s;

  --btn-bg-color: #efefef;

  &:not(:disabled) {
    cursor: pointer;
    background-color: var(--btn-bg-color);

    color: $black;
    border-color: $gray;

    &:hover {
      --btn-bg-color: #{$gray};
      color: $white;
      border-color: $white;
    }
  }
}

@mixin btn-primary(
  $pad: 20px,
  $text-transform: uppercase,
  $font-weight: bold,
) {
  @include button($pad, $text-transform);

  font-weight: $font-weight;

  --btn-bg-color: #{rgba($blue, 0.3)};
}

.button {
  @include button;
}

.primary {
  @include btn-primary(
    $text-transform: none // by historical reasons
  );
}

.secondary {
  @include btn-primary($pad: 12px 24px, $font-weight: normal);
}

.bare {
  border: none;
  --btn-bg-color: none;
}

.danger {
  --btn-bg-color: #{transparentize($error, 0.9)};
}
