.flex {
  margin-top: 20px;
  margin-bottom: 6px;
}

.data {
  margin-bottom: 10px;

  .title {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .value {
    border: 1px solid;
    min-width: 100px;
    padding: 4px 6px;
  }
}
