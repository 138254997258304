@import "styles/colors";

.wrapper {
  display: flex;
  border: 2px dashed $gray;

  & > div {
    margin: auto;
    font-size: 1.2rem;
  }
}
