@import "styles/components";

.form {
  /* css-modules empty */
}

.row {
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  // Required for Safari
  // @see https://utility.quantumobile.co/task?id=18342
  grid-auto-rows: max-content;
}

.footer {
  margin-top: 12px;
}

.ready_title {
  padding-top: 6px;
}
