.search {
  position: relative;
  width: 450px;
  height: 40px;
  padding-left: 50px;
  font-size: 1rem;
}

.search_wrapper {
  position: relative;

  &::before {
    content: "\260C";
    font-size: 2.3rem;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 5px;
    top: 10px;
    z-index: 10;
    transform: rotate(90deg);
    color: inherit;
    opacity: 0.7;
  }
}
