@import "styles/colors";

.controls {
  display: flex;
  align-items: center;
  gap: 12px;
  color: $black;
}

.dialog_reject_draft {
  width: 100%;
  text-align: left;
}
